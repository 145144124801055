import { initializeApp } from "firebase/app";
import { getAuth, signOut, signInWithEmailAndPassword } from "firebase/auth";
import {
  doc,
  getFirestore,
  getDoc,
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  where,
  getAggregateFromServer,
  sum,
} from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { BaseData } from "./util/const";

const config = {
  apiKey: "AIzaSyANnqaI1ZgyxdYN2wx3JM3JY6j3lA_YZEQ",
  authDomain: "html5-gaming-bot.firebaseapp.com",
  databaseURL: "https://html5-gaming-bot-default-rtdb.firebaseio.com",
  projectId: "html5-gaming-bot",
  storageBucket: "html5-gaming-bot.appspot.com",
  messagingSenderId: "150263918272",
  appId: "1:150263918272:web:5adfde1939e991336d4725",
  measurementId: "G-0DLPVEV1D3",
};

const app = initializeApp(config);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

export const login = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const logOut = async () => {
  await signOut(auth);
};

export async function getStats() {
  const statRef = doc(db, "/aBase/stats");
  const statDoc = await getDoc(statRef);

  return statDoc.data();
}
export async function getReferralLead() {
  const refRef = collection(db, "referrals");
  const q = query(
    refRef,
    where("username", "!=", null),
    orderBy("numReferrals", "desc"),
    limit(30)
  );
  const refSnaps = await getDocs(q);

  let refs = [];

  refSnaps.docs.forEach((d) => {
    refs.push({
      ...d.data(),
      uid: d.id,
    });
  });

  return refs;
}
export async function getReferralPartnerLead() {
  const refRef = collection(db, "referrals");
  const q = query(
    refRef,
    where("type", "==", "partner"),
    orderBy("numReferrals", "desc"),
    limit(30)
  );
  const refSnaps = await getDocs(q);

  let refs = [];

  refSnaps.docs.forEach((d) => {
    refs.push({
      ...d.data(),
      uid: d.id,
    });
  });

  return refs;
}
export async function getTasksLead() {
  const refRef = collection(db, "users-tasks");
  const q = query(refRef, orderBy("num_completed", "desc"), limit(30));
  const refSnaps = await getDocs(q);

  let refs = [];

  refSnaps.docs.forEach((d) => {
    refs.push({
      ...d.data(),
      uid: d.id,
    });
  });

  return refs;
}
export async function getLogs() {
  const refRef = collection(db, "logs");
  const q = query(refRef, orderBy("date", "desc"), limit(30));
  const refSnaps = await getDocs(q);

  let refs = [];

  refSnaps.docs.forEach((d) => {
    refs.push({
      ...d.data(),
      id: d.id,
    });
  });

  return refs;
}
export async function getPointsInfo() {
  const refRef = collection(db, "user-points");

  let summary = {
    tot_now: 0,
    tot_ever: 0,
    tot_partner: 0,
    tot_social_media: 0,
    tot_ads: 0,
  };

  let promis = [];
  promis.push(
    getAggregateFromServer(refRef, {
      points: sum("points"),
    }).then((d) => (summary.tot_now = d.data().points))
  );
  promis.push(
    getAggregateFromServer(refRef, {
      points: sum("total_points"),
    }).then((d) => (summary.tot_ever = d.data().points))
  );
  getAggregateFromServer(refRef, {
    points: sum("points_ads"),
  }).then((d) => (summary.tot_ads = d.data().points));
  promis.push(
    getAggregateFromServer(refRef, {
      points: sum("points_partner"),
    }).then((d) => (summary.tot_partner = d.data().points))
  );
  promis.push(
    getAggregateFromServer(refRef, {
      points: sum("points_social_media_eng"),
    }).then((d) => (summary.tot_social_media = d.data().points))
  );

  await Promise.all(promis);

  return summary;
}
