import { useEffect, useState } from "react";
import { getReferralLead } from "../firebase";
import { DescTremor, Tit2Tremor } from "../comps/coolComp";
import { RColumn, Space } from "../comps/otherCOmps";
import {
  Card,
  Tab,
  TabGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import RefUsersLead from "./refUser";
import PartenerLead from "./partenerLead";

export default function ReferralPage() {
  return (
    <>
      <Tit2Tremor>Referrals</Tit2Tremor>
      <Space />
      <DescTremor>(only 30)</DescTremor>
      <TabGroup>
        <TabList>
          <Tab>Users</Tab>
          <Tab>Partners</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <RefUsersLead />
          </TabPanel>
          <TabPanel>
            <PartenerLead />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </>
  );
}
