import { useEffect, useState } from "react";
import { Tit2Tremor } from "../comps/coolComp";
import { getPointsInfo, getStats } from "../firebase";
import { Card } from "@tremor/react";
import { Space } from "../comps/otherCOmps";
import { formatNumberInStr } from "../util/util";

export default function DashboardPage() {
  const [stats, setStats] = useState();

  const [pointsSummary, setPointsSummary] = useState({});

  const [topData, setTopData] = useState([
    { name: "Total earnings", stat: 0, change: 0, changeType: "positive" },
    { name: "PVP volume", stat: 0, change: 0, changeType: "positive" },
    { name: "Total games wins", stat: 0, change: 0, changeType: "positive" },
    { name: "Total P2E earnings", stat: 0, change: 0, changeType: "positive" },
  ]);
  useEffect(() => {
    getStats().then((s) => setStats(s));
    getPointsInfo().then((p) => setPointsSummary(p));
  }, []);

  useEffect(() => {
    if (!stats) return;
    setTopData([
      {
        name: "Total earnings",
        stat: "$" + formatNumberInStr(stats.total_earnings),
        change: 0,
        changeType: "positive",
      },
      {
        name: "PVP volume",
        stat: "$" + formatNumberInStr(stats.total_volume),
        change: 0,
        changeType: "positive",
      },
      {
        name: "Total games wins",
        stat: formatNumberInStr(stats.total_games_win),
        change: 0,
        changeType: "positive",
      },
      {
        name: "Total P2E earnings",
        stat: "$" + formatNumberInStr(stats.p2e_attemps_earnings),
        change: 0,
        changeType: "positive",
      },
    ]);
  }, [stats]);

  return (
    <>
      <Tit2Tremor>Dashboard</Tit2Tremor>
      <Space />
      <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {topData.map((item) => (
          <Card key={item.name}>
            <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
              {item.name}
            </dt>
            <dd className="mt-2 flex items-baseline space-x-2.5">
              <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                {item.stat}
              </span>
            </dd>
          </Card>
        ))}
      </dl>
      <Space />
      Points
      <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <Card>
          <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            Total points given
          </dt>
          <dd className="mt-2 flex items-baseline space-x-2.5">
            <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {formatNumberInStr(pointsSummary.tot_now)}
            </span>
          </dd>
        </Card>
        <Card>
          <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            Total points ads
          </dt>
          <dd className="mt-2 flex items-baseline space-x-2.5">
            <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {formatNumberInStr(pointsSummary.tot_ads)}
            </span>
          </dd>
        </Card>
        <Card>
          <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            Total points partner
          </dt>
          <dd className="mt-2 flex items-baseline space-x-2.5">
            <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {formatNumberInStr(pointsSummary.tot_partner)}
            </span>
          </dd>
        </Card>
        <Card>
          <dt className="text-tremor-default font-medium text-tremor-content dark:text-dark-tremor-content">
            Total points social media
          </dt>
          <dd className="mt-2 flex items-baseline space-x-2.5">
            <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
              {formatNumberInStr(pointsSummary.tot_social_media)}
            </span>
          </dd>
        </Card>
      </dl>
    </>
  );
}
