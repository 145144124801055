export function formatNumberInStr(num) {
  if (!num) return "0";

  if (num >= 1e9) {
    // Billion
    return (
      (num / 1e9).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }) + "B"
    );
  } else if (num >= 1e6) {
    // Million
    return (
      (num / 1e6).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }) + "M"
    );
  } else if (num >= 1e3) {
    // Thousand
    return (
      (num / 1e3).toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }) + "K"
    );
  } else {
    // Less than thousand
    return num.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    });
  }
}
